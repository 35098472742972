module.exports = {
  seo: {
    title: "Jenko - Projects",
    description: "",
  },
  intro: {
    title: "Projects",
    desc: "We provide guidance and consultation on product development and planning - from the initial idea stages all the way to a winning\u00A0final\u00A0product",
  },
  projects: [
    {
      title: "E-Turbine",
      desc: "High speed & pressure Compressor system, 20cm\u00A0length",
    },
    {
      title: "Baby Safe",
      desc: "A system with easy installation in the vehicle, which alerts the driver by sending a message to the cellphone that he has forgotten the child in\u00A0the\u00A0vehicle",
    },
    {
      title: "Display Case for\u00A0Guitar",
      desc: "A luxurious carrying system for the guitar, which allows on the one hand to move the guitar in a convenient and innovative way and on the other hand to present the guitar as\u00A0a\u00A0display",
    },
    {
      title: "Green Roller",
      desc: "Color correction roller. Easy to clean after use, the color does not dry when\u00A0closed",
    },
    {
      title: "Modern Pocketknife",
      desc: "A kit that combines mobile charging, mobile battery, charging connections for all types of mobile devices, screwdriver, bottle / box opener, knife\u00A0and\u00A0flashlight",
    },
    {
      title: "Shooter Detection System",
      desc: "A real-time firing detection system that alerts about firing occurring near\u00A0the\u00A0detector",
    },
    {
      title: "Cutting Disk Attachment",
      desc: "A variation of a Cutting disk suitable for any drill / screwdriver due to a special\u00A0adapter",
    },
    {
      title: "Catheter\u00A0Securement\u00A0Device",
      desc: "The world’s first Foley catheter stabilizer which actively prevents possible damage to the bladder and urethra from accidental urinary catheter\u00A0extraction\u00A0(cathetrix.com)",
    },
    {
      title: "Smart Ring",
      desc: "A ring that combines LED bulbs that warn of distancing from the mobile by a vibration mechanism in case of loss / theft / forgetting of\u00A0the\u00A0mobile",
    },
    {
      title: "Apple TV Adapter",
      desc: "An adapter that provides presence and volume to prevent the loss of the little remote, protects it from falls, simulating a game console\u00A0joystick",
    },
    {
      title: "Obstacle Detection System",
      desc: "An electronic system that installed in the mobile phone cover and alerts the user from obstacles along the way while using the\u00A0cellphone",
    },
    {
      title: "E-cigarette Roller",
      desc: "Electric and automatic e-cigarette roller that does not require an electrical connection, you can roll many cigarettes\u00A0automatically",
    },
  ],
  outro:
    "orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  dir: "ltr",
};
