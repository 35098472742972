import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "../../styles/pages/projects/index.module.css";

const ProjectsGrid = (props) => {
  const {
    sProjectGridSec,
    sProjectGridContainer,
    sProjectElem,
    sProjectElemOverlay,
  } = styles;
  const { projects, dir } = props;
  const elemTouched = (event) => {
    event.target.style.opacity = "0.1";
    const eventTarget = event.target;
    setTimeout(() => {
      eventTarget.style.opacity = "1";
    }, 2000);
  };
  return (
    <section className={sProjectGridSec}>
      <div className={sProjectGridContainer}>
        <div className={sProjectElem} onTouchStart={(e) => elemTouched(e)}>
          <StaticImage
            src="../../assets/images/projects/turbina.png"
            alt="E-Turbine"
            layout="fullWidth"
          />
          <div className={sProjectElemOverlay}>
            <div dir={dir}>
              <h3>{projects[0].title}</h3>
              <p>{projects[0].desc}</p>
            </div>
          </div>
        </div>
        <div className={sProjectElem} onTouchStart={(e) => elemTouched(e)}>
          <StaticImage
            src="../../assets/images/projects/babysafe.png"
            alt="Baby Safe"
            layout="fullWidth"
          />
          <div className={sProjectElemOverlay}>
            <div dir={dir}>
              <h3>{projects[1].title}</h3>
              <p>{projects[1].desc}</p>
            </div>
          </div>
        </div>
        <div className={sProjectElem} onTouchStart={(e) => elemTouched(e)}>
          <StaticImage
            src="../../assets/images/projects/guitar.png"
            alt="GuitarCase"
            layout="fullWidth"
          />
          <div className={sProjectElemOverlay}>
            <div dir={dir}>
              <h3>{projects[2].title}</h3>
              <p>{projects[2].desc}</p>
            </div>
          </div>
        </div>
        <div className={sProjectElem} onTouchStart={(e) => elemTouched(e)}>
          <StaticImage
            src="../../assets/images/projects/green-roller.png"
            alt="GreenRoller"
            layout="fullWidth"
          />
          <div className={sProjectElemOverlay}>
            <div dir={dir}>
              <h3>{projects[3].title}</h3>
              <p>{projects[3].desc}</p>
            </div>
          </div>
        </div>
        <div className={sProjectElem} onTouchStart={(e) => elemTouched(e)}>
          <StaticImage
            src="../../assets/images/projects/sk.png"
            alt="ModernPocketknife"
            layout="fullWidth"
          />
          <div className={sProjectElemOverlay}>
            <div dir={dir}>
              <h3>{projects[4].title}</h3>
              <p>{projects[4].desc}</p>
            </div>
          </div>
        </div>
        <div className={sProjectElem} onTouchStart={(e) => elemTouched(e)}>
          <StaticImage
            src="../../assets/images/projects/shoting-device.png"
            alt="Smart ring"
            layout="fullWidth"
          />
          <div className={sProjectElemOverlay}>
            <div dir={dir}>
              <h3>{projects[5].title}</h3>
              <p>{projects[5].desc}</p>
            </div>
          </div>
        </div>
        <div className={sProjectElem} onTouchStart={(e) => elemTouched(e)}>
          <StaticImage
            src="../../assets/images/projects/assem.png"
            alt="Cutting disk attachment"
            layout="fullWidth"
          />
          <div className={sProjectElemOverlay}>
            <div dir={dir}>
              <h3>{projects[6].title}</h3>
              <p>{projects[6].desc}</p>
            </div>
          </div>
        </div>
        <div className={sProjectElem} onTouchStart={(e) => elemTouched(e)}>
          <StaticImage
            src="../../assets/images/projects/catetrix.png"
            alt="Cateter cutting mechanism"
            layout="fullWidth"
          />
          <div className={sProjectElemOverlay}>
            <div dir={dir}>
              <h3>{projects[7].title}</h3>
              <p>{projects[7].desc}</p>
            </div>
          </div>
        </div>
        <div className={sProjectElem} onTouchStart={(e) => elemTouched(e)}>
          <StaticImage
            src="../../assets/images/projects/ring.png"
            alt="Smart ring"
            layout="fullWidth"
          />
          <div className={sProjectElemOverlay}>
            <div dir={dir}>
              <h3>{projects[8].title}</h3>
              <p>{projects[8].desc}</p>
            </div>
          </div>
        </div>
        <div className={sProjectElem} onTouchStart={(e) => elemTouched(e)}>
          <StaticImage
            src="../../assets/images/projects/joystick.png"
            alt="Apple TV Adapter"
            layout="fullWidth"
          />
          <div className={sProjectElemOverlay}>
            <div dir={dir}>
              <h3>{projects[9].title}</h3>
              <p>{projects[9].desc}</p>
            </div>
          </div>
        </div>
        <div className={sProjectElem} onTouchStart={(e) => elemTouched(e)}>
          <StaticImage
            src="../../assets/images/projects/smart-mobile.png"
            alt="Obstacle detection system"
            layout="fullWidth"
          />
          <div className={sProjectElemOverlay}>
            <div dir={dir}>
              <h3>{projects[10].title}</h3>
              <p>{projects[10].desc}</p>
            </div>
          </div>
        </div>
        <div className={sProjectElem} onTouchStart={(e) => elemTouched(e)}>
          <StaticImage
            src="../../assets/images/projects/rolo.png"
            alt="E-cigarette roller"
            layout="fullWidth"
          />
          <div className={sProjectElemOverlay}>
            <div dir={dir}>
              <h3>{projects[11].title}</h3>
              <p>{projects[11].desc}</p>
            </div>
          </div>
        </div>

        {/* 
                    <div className={sProjectElem}> </div>
                    <div className={sProjectElem}> </div> */}
      </div>
    </section>
  );
};

export default ProjectsGrid;
